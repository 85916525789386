import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LangType, StateService } from '../../../../core/services/state.service';
import { CommunicationService } from 'src/app/core/services/communication.service'
import { combineLatest } from 'rxjs';
import { MultiScenarioComponent } from '../multi-scenario/multi-scenario.component';
import { distinct, filter } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeactivationModal } from '../confirmation-modals/deactivation-confirm-modal/deactivate-confirm-modal.component';
import { ConfirmSwitchDeactivationModal } from '../confirmation-modals/switch-deactivate-confirm-modal/switch-deactivate-confirm-modal.component';
import { DialogResponse } from '../../../../core/constants/dialog_response.constant';
import { DEACTIVATE_SCENARIO, GET_CURRENT_TREATMENT_PLAN } from 'src/app/core/constants/graphql.query.constants';
import { AddonsComponent, AddonDialogResult } from '../addons/addons.component';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { Subscription } from 'rxjs';
import { MedicalContentNames, Scenario, TreatmentPlan, ActiveScenario, TreatmentPlanTypesConfig } from 'src/app/core/model/common.interface';
import _ from 'lodash';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-multi-treatment-plan',
  templateUrl: './multi-treatment-plan.component.html',
  styleUrls: ['./multi-treatment-plan.component.scss']
})
export class MultiTreatmentPlanComponent implements OnInit, OnDestroy {
  activatedTreatmentId: string;
  lang: LangType;
  treatmentPlans: TreatmentPlan[] = [];
  scenarios: Scenario[] = [];
  medicalContentNames: MedicalContentNames;
  selectedTreatmentPlanType: TreatmentPlanTypesConfig;
  selectedTreatmentPlanScenarios: string[];
  mainActiveScenarioTitleIds: string[];
  mainActiveScenarioTitle: string = '';
  otherActiveScenarioTitles: string[] = [];
  activatedScenarioIds: string[] = [];
  private subs: Subscription[] = [];
  private targetSelector;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  treatmentPlanTypesConfig: TreatmentPlanTypesConfig[];

  constructor(public stateService: StateService,
    private patientStateService: PatientStateService, private communicationService: CommunicationService,
    private dialog: MatDialog, private viewRef: ViewContainerRef, private apollo: Apollo) {
    const deactivateTreatmentPlanSub = communicationService.deactivateTreatmentPlan$.subscribe(
      data => {
        const treatmeant = [].concat(this.treatmentPlans.values()).find(t => t && t.id === data['scenarioId'])
        if (treatmeant.active) {
          this.openMultiScenarioView(treatmeant, false);
          communicationService.rejectOpenRecommendation(data['openRecommendationId']);
        }
      }
    );
    this.subs.push(deactivateTreatmentPlanSub);
    const reloadTreatmentPlanSub = communicationService.reloadTreatmentPlans$.subscribe((activityFilter: Map<string, string>) => {
      this.loadTreatmentPlans();
    });
    this.subs.push(reloadTreatmentPlanSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }


  ngOnInit(): void {
    this.stateService.lang.subscribe((lang) => {
      this.lang = lang;
    });
    const activeRecommendationSub = this.communicationService.activateRecommendationScenario$.subscribe(scenarioId => {
      const treatment = this.scenarios.filter((scenario) => scenario.id === scenarioId)[0];
      if (treatment) {
        this.openMultiScenarioView(treatment, true);
      }
    });
    this.subs.push(activeRecommendationSub);
    this.loadTreatmentPlans();
  }


  afterExpandHandler() {
    this.targetSelector.scrollIntoView({ behavior: 'smooth'});
  }

  afterExpandHeaderHandler($event): void {
    let className1 = '';
    $event.currentTarget.nextSibling.classList.forEach((data: String) => {
      className1 += "." + data;
    });
    this.targetSelector = document.querySelector(className1);
  }

  private loadTreatmentPlans(): void {
    const getCurrentTreatmentPlanObs = this.apollo
      .query({
        query: GET_CURRENT_TREATMENT_PLAN,
      });
    const treatmentPlanSub = combineLatest([
      this.patientStateService.diseaseIdSubject$.pipe(filter(id => !!id), distinct()),
      this.stateService.medicalContentNamesObs,
      this.stateService.medicalContentObs,
      getCurrentTreatmentPlanObs
    ]).subscribe(([diseaseId, medicalContentNames, medicalContent, currentTreatmentPlan]) => {
      setTimeout(() => {
        const currentTreatmentPlanData = currentTreatmentPlan.data['getCurrentTreatmentPlan'];
        this.medicalContentNames = medicalContentNames;
        const medicalContents = { ...medicalContent };
        this.treatmentPlans = _.cloneDeep(medicalContents.diseases.filter((disease) => disease.id === diseaseId)[0].treatmentPlan);
        this.treatmentPlanTypesConfig = _.cloneDeep(medicalContents.diseases.filter((disease) => disease.id === diseaseId)[0].treatmentPlanTypesConfig);
        this.treatmentPlanTypesConfig.forEach((data) => {
          if (data.showAsActiveScenarioTitle) {
            this.mainActiveScenarioTitleIds = data.scenarioIds;
          }
        })
        this.treatmentPlans.forEach((treatmentPlan) => {
          treatmentPlan.scenarios.forEach((scenario) => {
            if (currentTreatmentPlanData?.activescenarios.length > 0) {
              currentTreatmentPlanData.activescenarios.forEach((activeScenario) => {

                if (activeScenario.scenarioId === scenario.id) {
                  scenario.active = true;
                  scenario.startDate = activeScenario.startDate;
                  if (this.mainActiveScenarioTitleIds.indexOf(activeScenario.scenarioId) >= 0) {
                    this.mainActiveScenarioTitle = scenario.name[this.lang];
                  } else {
                    this.otherActiveScenarioTitles.push(scenario.name[this.lang]);
                  }
                  currentTreatmentPlanData.activeaddons.forEach((activeAddon) => {
                    const filteredAddon = scenario.addons.filter((addon) => addon.id === activeAddon.addonId)[0];
                    if (filteredAddon) {
                      filteredAddon.active = {
                        startDate: new Date(activeAddon.startDate),
                        ...this.buildOffset(activeAddon.startOffset)
                      };
                    }
                  });
                }
              });
            }
            this.scenarios.push(scenario);
          })
        })
      }, 0)

    });
    this.subs.push(treatmentPlanSub);
  }

  buildOffset(startOffset = 0) {
    if (startOffset % 30 == 0) {
      return { startOffset: startOffset / 30, offsetType: 'month' }
    } else {
      return { startOffset: startOffset / 7, offsetType: 'week' }
    }
  }

  toggleTreatmentPlan(selectedTreatmentId: string, scenarioActive: boolean, startDate?: string): void {
    this.treatmentPlans.forEach((treatmeantPlan) => {
      treatmeantPlan.scenarios.map((scenario) => {
        if (this.selectedTreatmentPlanScenarios?.indexOf(scenario.id) >= 0 || this.treatmentPlanTypesConfig.length === 0) {
          if (scenario.id !== selectedTreatmentId) {
            scenario.active = false;
            if (scenario.addons && (scenario.addons.length > 0)) {
              scenario.addons.forEach(addon => {
                addon.active = null;
              });
            }
          }
          else {
            scenario.active = scenarioActive;
            if (startDate)
              scenario.startDate = startDate;
          }
        }
      });
    });
  }

  openMultiScenarioDialog(selectedTreatment: Scenario) {
    const dialogRef = this.dialog.open(MultiScenarioComponent, {
      viewContainerRef: this.viewRef,
      disableClose: true,
      maxWidth: '600px',
      data: {
        scenarioId: selectedTreatment.id
      },
    });
    dialogRef.afterClosed().subscribe(({ canceled, startDate }) => {
      if (canceled) {
        selectedTreatment.active = false;
      } else {
        if (this.selectedTreatmentPlanType.showAsActiveScenarioTitle) {
          this.mainActiveScenarioTitle = selectedTreatment.name[this.lang];
        } else {
          this.otherActiveScenarioTitles.push(selectedTreatment.name[this.lang]);
        }
        this.selectedTreatmentPlanType?.multipleScenariosAllowed ? selectedTreatment.active = true : this.toggleTreatmentPlan(selectedTreatment.id, true, startDate);
      }
    });
  }

  getDeactivationModalOptions(selectedTreatment: string) {
    return {
      viewContainerRef: this.viewRef,
      disableClose: true,
      maxWidth: '500px',
      data: {
        scenarioName: selectedTreatment
      }
    }
  }
  openMultiScenarioView(selectedTreatment: Scenario, activateSameOnDeactivate: boolean): void {
    let hasScenarioActivatedInGroup: boolean;
    let selectedScenarioTileInGroup: string;
    const selectedTreatmentId = selectedTreatment.id;
    this.treatmentPlanTypesConfig.forEach((data) => {
      if (data.scenarioIds.indexOf(selectedTreatmentId) >= 0) {
        this.selectedTreatmentPlanType = data;
        this.selectedTreatmentPlanScenarios = _.cloneDeep(data.scenarioIds);
      }
    });

    this.selectedTreatmentPlanScenarios.forEach((id) => {
      const data: Scenario[] = this.scenarios.filter(scenario => scenario.id === id && scenario.active);
      if (data.length > 0) {
        hasScenarioActivatedInGroup = true;
        selectedScenarioTileInGroup = data[0].name[this.lang]
        return;
      }
    })


    if (!this.selectedTreatmentPlanType.multipleScenariosAllowed && !selectedTreatment.active && hasScenarioActivatedInGroup) {
      const confirmSwitchDeactivate = this.dialog.open(ConfirmSwitchDeactivationModal, this.getDeactivationModalOptions(selectedScenarioTileInGroup));
      confirmSwitchDeactivate.afterClosed().subscribe(result => {
        result === DialogResponse.CONFIRM ? this.openMultiScenarioDialog(selectedTreatment) : selectedTreatment.active = false;
      });
    } else if (!selectedTreatment.active) {
      this.openMultiScenarioDialog(selectedTreatment);
    } else {
      const dialogRef = this.dialog.open(ConfirmDeactivationModal, this.getDeactivationModalOptions(selectedTreatment.name[this.lang]));
      dialogRef.afterClosed().subscribe(result => {
        if (result === DialogResponse.CONFIRM) {
          this.apollo.mutate({
            mutation: DEACTIVATE_SCENARIO,
            variables: {
              diseaseId: this.patientStateService.diseaseId,
              scenarioIdToDeactivate: selectedTreatmentId
            }
          }).subscribe((result:any) => {
            const data = result.data;
            this.patientStateService.setTreatmentInstanceId = null;
            this.patientStateService.setTreatmentId = null;
            this.patientStateService.setActiveAddon = null;
            this.activatedTreatmentId = null;
            const canceledActivites = new Set<string>();
            // IE11 does not support new Set(array)
            data['deactivateScenario'].canceledActivities?.forEach(activity => {
              canceledActivites.add(activity.activityId);
            });
            this.patientStateService.setCarePathwayActivites = this.patientStateService.carePathwayActivities?.filter(
              (activity) => (!canceledActivites.has(activity.activityId))
            );

            if (activateSameOnDeactivate) {
              selectedTreatment.active = false;
              this.openMultiScenarioView(selectedTreatment, false);
            }
            if (this.selectedTreatmentPlanType.showAsActiveScenarioTitle) {
              this.mainActiveScenarioTitle = '';
            } else {
              const titleIndex = this.otherActiveScenarioTitles.indexOf(selectedTreatment.name[this.lang]);
              this.otherActiveScenarioTitles.splice(titleIndex, 1);
            }
          });

          setTimeout(() => {
            this.toggleTreatmentPlan(selectedTreatmentId, false);
          }, 200);
          this.toggleAddon(selectedTreatment);
        } else if (result === DialogResponse.CANCEL) {
          selectedTreatment.active = true;
        }
      })
    }
  }

  addOnAction(scenario: ActiveScenario): void {
    const dialogRef = this.dialog.open(AddonsComponent, {
      viewContainerRef: this.viewRef,
      minWidth: '500px',
      panelClass: 'activate-addon',
      data: {
        scenario
      }
    });
    dialogRef.afterClosed().subscribe((result: AddonDialogResult) => {
      this.scenarios.filter(scenario => scenario.active).forEach(activeScenario => {
        activeScenario.addons.forEach(addon => {
          if (result.deactivatedAddons?.includes(addon.id)) {
            addon.active = null;
          } else if (Object.keys(result.activatedAddons)?.includes(addon.id)) {
            addon.active = result.activatedAddons[addon.id];
          }
        });
      });
    });
  }

  toggleAddon(scenario: Scenario) {
    scenario.addons.forEach(addon => {
      addon.active = null;
    })
  }
}
