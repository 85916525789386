<header class="{{ isNewHeader ? 'new-header' : '' }}">
    <nav class="container-fluid navbar navbar-dark navbar-expand-lg" [ngClass]="{ 'container-md': !isNewHeader}">
        <a class="navbar-brand" (click)="navigateToOverview()" href="javascript:void(0);">
            <img [src]="isNewHeader ? 'assets/images/logo/logo-new.png' : 'assets/images/logo/dh-logo.png'"  class="DEARHealth" alt="DEARHealth">
            <!-- <img [src]="isDarkMode ? 'assets/images/logo/dh-logo-dark.png' : 'assets/images/logo/dh-logo.png'" alt="Logo"> -->
    
        </a>
        <div class="mr-auto"></div>
       
        <div class="navbar-right-wrapper">
            <div class="nav-item dropdown language-block">
                <div data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                    class="language-dropdown" (click)="toggleLang($event)">
                    <img *ngIf="currentLanguage() === 'en'" src="assets/images/logo/en.png">
                    <img *ngIf="currentLanguage() === 'nl'" src="assets/images/logo/nl.png">
                    <span class="text-uppercase" *ngIf="!isNewHeader"> {{ currentLanguage() }} </span>
                    <i class="fa fa-caret-down" *ngIf="!isNewHeader"></i>
                </div>
                <div class="dropdown-menu dropdown-menu-right col-4" aria-labelledby="dropdownMenuButton" id="languages">
                    <a class="gray-1 dropdown-item" (click)="enLanguage()">
                        <img src="assets/images/logo/en.png">
                        <span>{{'english' | translate}} </span>
                    </a>
                    <a class="gray-1 dropdown-item" (click)="nlLanguage()">
                        <img src="assets/images/logo/nl.png">
                        <span>{{'dutch' | translate}} </span>
                    </a>
                </div>
            </div>
            <div class="" id="navbarSupportedContent">
                <div class="mr-auto"></div>
                <ul class="navbar-nav">
                    <li class="nav-item dropdown profile-block">
                        <button mat-mini-fab type="button" aria-haspopup="false" aria-expanded="false"
                            id="profileButton" data-toggle="dropdown" (click)="displayMenu($event)">
                            {{ currentUserInitials }}
                        </button>
                        <div class="dropdown-menu col-5" aria-labelledby="profileButton" id="profile">
                            <div class="dropdown-header gray-1">
                                <span class="h6">
                                    <i class="fa fa-user-circle"></i>
                                    <span class="pl-2">{{ currentUserFullName }}</span>
                                </span>
                            </div>
                            <div class="dropdown-item cursor-pointer gray-1" (click)="signOut()">
                                <i class="fa fa-sign-out"></i>
                                <span class="pl-2">{{ 'sign_out' | translate }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- <div *ngIf="isNewHeader" class="notification" >
                <mat-icon>notifications_none</mat-icon>
            </div> -->
        </div>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span> -->
    <!-- </button> -->

    </nav>
</header>