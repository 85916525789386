import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordLessAuthService } from 'src/app/core/services/passwordless.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/core/services/message.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-passwordless-initiation',
  templateUrl: './passwordless-initiation.component.html',
  styleUrls: ['../auth-style.scss']
})
export class PasswordlessInitiationComponent {

  @Output() initiationComplete = new EventEmitter<string>();
  @ViewChild('email') email;
  form: FormGroup;

  public loading = false;

  constructor(private fb: FormBuilder, private passwordLessAuthService: PasswordLessAuthService, private messageService: MessageService,
    private stateService: StateService, private router: Router, private translate: TranslateService, private loaderService: LoaderService) {
      this.stateService.lang.subscribe(res => {
        this.translate.use(res);
      });
    this.form = fb.group({
      email: ['',  Validators.compose([Validators.required, Validators.email])],
    });
  }
  ngAfterViewChecked() {
    this.email.nativeElement.focus();
  }

  async initiateEmailVerification(): Promise<void> {
    try {
      this.loaderService.show();
      if (this.form.valid) {
        this.loading = true;
        const email = this.form.value.email;
        const data = await this.passwordLessAuthService.signIn(email);
        this.router.navigateByUrl('/email-verify', { state: {...data, ...{'userName': email}} });
      }
    } catch (error) {
      this.messageService.showError('Email verification failed');
    } finally {
      this.loading = false;
      this.loaderService.hide();
    }
  }
}

// import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { PasswordLessAuthService } from 'src/app/core/services/passwordless.service';
// import { TranslateService } from '@ngx-translate/core';
// import { MessageService } from 'src/app/core/services/message.service';
// import { LoaderService } from 'src/app/core/services/loader.service';
// import { StateService } from 'src/app/core/services/state.service';

// @Component({
//   selector: 'app-passwordless-initiation',
//   templateUrl: './passwordless-initiation.component.html',
//   styleUrls: ['../auth-style.scss']
// })
// export class PasswordlessInitiationComponent {

//   @Output() initiationComplete = new EventEmitter<string>();
//   @ViewChild('email') email;
//   form: FormGroup;

//   public loading = false;

//   constructor(private fb: FormBuilder, private passwordLessAuthService: PasswordLessAuthService, private messageService: MessageService,
//     private stateService: StateService, private router: Router, private translate: TranslateService, private loaderService: LoaderService) {
//       this.stateService.lang.subscribe(res => {
//         this.translate.use(res);
//       });
//     this.form = fb.group({
//       email: ['',  Validators.compose([Validators.required, Validators.email])],
//     });
//   }
//   ngAfterViewChecked() {
//     this.email.nativeElement.focus();
//   }

//   async initiateEmailVerification(): Promise<void> {
//     try {
//       this.loaderService.show();
//       if (this.form.valid) {
//         this.loading = true;
//         const email = this.form.value.email;
//         const data = await this.passwordLessAuthService.signIn(email);
//         this.router.navigateByUrl('/email-verify', { state: {...data, ...{'userName': email}} });
//       }
//     } catch (error) {
//       this.messageService.showError('Email verification failed');
//     } finally {
//       this.loading = false;
//       this.loaderService.hide();
//     }
//   }
// }
