import { Component, ElementRef, Input, OnChanges, OnDestroy, AfterViewInit, ViewChild, SimpleChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { createRoot, Root } from 'react-dom/client';
import React from 'react';
import Overview from "@dearhealth-components/overview";
import { ColumnDefinition } from '../core/model/overview.interface';

@Component({
  selector: 'app-react-component',
  template: '<div #reactContainer></div>',
  // styleUrls: ['../../../node_modules/@dearhealth-components/overview/dist/index.css'],
})
export class ReactComponentWrapper implements OnChanges, OnDestroy, AfterViewInit {
  @Input() totalCount: number;
  @Input() data!: any;
  @Input() filterOptions!: any;
  @Input() columnOptions!: ColumnDefinition[];
  @Input() lang!: string;
  @Input() payload: any;
  @Output() filterChanges = new EventEmitter<any>();
  @Output() notificationChange = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() loadMore = new EventEmitter();
  @Output() patientCreate = new EventEmitter();

  @ViewChild('reactContainer', { static: false }) reactContainer!: ElementRef;

  private root: Root | null = null;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (!this.root) {
      this.root = createRoot(this.reactContainer.nativeElement);
      this.renderReactComponent();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root) {
      this.renderReactComponent();
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.unmount();
    }
  }

  handleFilterChanges = (data): void => {
    this.filterChanges.emit(data);
  }

  handleNotificationChange = (notificationId: string, rowData: any): void => {
    this.notificationChange.emit({notificationId, rowData});
  } 

  handleRowClick = (data): void => {
    this.rowClick.emit(data);
  }

  handleLoadMore = (): void => {
    this.loadMore.emit();
  }

  handlePatientCreate = (): void => {
    this.patientCreate.emit();
  }

  private renderReactComponent(): void {
    this.root!.render(
      <React.StrictMode>
        <Overview 
          payload={this.payload}
          totalCount={this.totalCount}
          data={this.data} 
          filterOptions={this.filterOptions}
          columnDefinitions={this.columnOptions}
          lang={this.lang}
          overviewChanges={this.handleFilterChanges}
          notificationChange={this.handleNotificationChange}
          rowClick={this.handleRowClick}
          loadMore={this.handleLoadMore}
          patientCreate={this.handlePatientCreate}
        />
      </React.StrictMode>
    );
  }
}