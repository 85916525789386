import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { PatientOverviewRecord } from 'src/app/core/model/overview.interface';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import * as moment from 'moment';
import { APP_CONSTANTS } from 'src/app/core/constants/app.constants';
import { CREATE_PATIENT, GET_PHYSICIANS_BY_DISEASE } from 'src/app/core/constants/graphql.query.constants';
import { Dropdown } from 'src/app/core/model/common.interface';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { MessageService } from 'src/app/core/services/message.service';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { CreatePatientForms } from 'src/app/dh-common/components/create-patient-forms/create-patient.forms';


@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class CreatePatientComponent extends CreatePatientForms implements OnInit {
  lang: LangType;
  // diseaseList: IDropdown[];
  // physiciansList: IDropdown[];

  constructor(private _formBuilder: FormBuilder, private stateService: StateService, private messageService: MessageService, private translate: TranslateService,
    private apollo: Apollo, public dialogRef: MatDialogRef<CreatePatientComponent>, private communicationService: CommunicationService) {
    super(_formBuilder);
  }

  ngOnInit() {
    this.stateService.lang.subscribe((data) => {
      this.lang = data;
    })
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  createPatient() {
    const careFormPayload = {
      diseasePractitionerMapping: [{
        diseaseId: this.careFormGroup.get('diseaseId').value,
        practitionerId: this.careFormGroup.get('practitionerId').value
      }]
    }
    const payload = { ...this.generalFormGroup.value, ...this.contactFormGroup.value, ...careFormPayload };
    payload.dob = moment(`${payload.month}-${payload.date}-${payload.year}`, 'MM-DD-YYYY').format(APP_CONSTANTS.YYYY_MM_DD_FORMAT);
    ['date', 'month', 'year'].forEach(prop => delete payload[prop]);
    const createPatientObs = this.apollo.mutate({
      mutation: CREATE_PATIENT,
      variables: {
        input: payload
      }
    });
    createPatientObs.subscribe((res) => {
      const patientData: PatientOverviewRecord = {
        patientId: res['data']['onboardPatient'].patientId, 
        diseaseId: [payload.diseasePractitionerMapping[0].diseaseId],
        scenarioId: [],
        notifications: [],
        lastName: payload.lastName,
        initials: payload.initials,
        birthDate: payload.dob,
        lastLogin: null,
        externalId: payload.externalId, 
        lastEcareDateTime: null,
        recommendations: []
      }
      this.communicationService.refreshOverviewCreatePatient(patientData);
      this.dialogRef.close(patientData);

    }, (err: string) => {
      if (err['graphQLErrors'][0].errorType === 'PatientAlreadyExists') {
        this.messageService.showError(this.translate.instant('create_patient.patient_exists_text'))
      } else {
        this.messageService.showGenericError();
      }
    })
  }
}
