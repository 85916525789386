import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CREATE_CONVERSATION, GET_CONVERSATIONS, GET_MESSAGE_BY_CONVERSATION_ID, SEND_MESSAGE } from 'src/app/core/constants/graphql.query.constants';
import { MedicalContent } from "src/app/core/model/common.interface";
import { NavigationService } from 'src/app/core/services/navigation.service';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { StateService } from "src/app/core/services/state.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {
  @ViewChild('chat') private chatContainer: ElementRef;
  messages = [];
  currmessage = "";
  currentUserCreds = ""
  messageSent = false;
  private querySubscription: Subscription;
  private conversationId: string = '';
  private nextMessageToken = '';
  private dateTimeFormat: string;
  content: Element;
  messageTextArea: Element;
  scroll$: Observable<number>;
  medicalContent: MedicalContent;
 

  constructor(private navigationService: NavigationService, private apollo: Apollo, private patientStateService: PatientStateService, 
        private stateService: StateService) {
    const currentUser = sessionStorage.getItem('currentUser') ? JSON.parse(sessionStorage.getItem('currentUser')) : undefined;
    this.currentUserCreds = currentUser ? `${currentUser.firstName.substring(0, 1)} ${currentUser.lastName}` : '';

  }

  sendMessage() {
    if (this.currmessage !== ''){
      this.currmessage = this.currmessage.replace(new RegExp('\n', 'g'), "<br />")
      const currmessageDateTime = new Date(Date.now()).toISOString()
      this.messages.push({ contents: this.currmessage, createdAt: currmessageDateTime, senderName: this.currentUserCreds });
      this.apollo.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          input: {
            contents: this.currmessage,
            conversationId: this.conversationId
          }
        }
      }).subscribe(() => {

      });

      this.currmessage = "";
      this.messageSent = true;
    }
  }

  ngAfterViewChecked() {
    if (this.messageSent) {
      this.scrollToBottom();
      this.messageSent = false;
    }
  }

  ngOnInit() {
    if (this.conversationId.length === 0) {
      this.querySubscription = this.apollo
        .query({
          query: GET_CONVERSATIONS,
        }).subscribe(({ data }) => {
          if (data['getConversations'] && data['getConversations'].items.length === 0) {
            this.createConversation();
          } else {
            this.conversationId = data['getConversations'].items[0].conversation.providerConversationId;
            this.getMessages(true);
          }
        });
    } else {
      this.getMessages(true);
    }
    
    this.content = document.querySelector('.chat');
    this.scroll$ = fromEvent(this.content!, 'scroll').pipe(map(() => { return this.content!.scrollTop; })) as unknown as Observable<number>
    this.medicalContent = this.stateService.getMedicalContent;
    this.dateTimeFormat = this.medicalContent.formatting.dateTimeFormat;
    this.scroll$.subscribe((scrollPos) => {
      if (scrollPos === 0 && this.nextMessageToken) {
        this.getMessages(false);
      }
    });

  }
  getMessages(scrollToBottom: boolean = false) {
    this.apollo
        .query({
          query: GET_MESSAGE_BY_CONVERSATION_ID,
          variables: {
            limit: 20, 
            conversationId: this.conversationId,
            nextToken: this.nextMessageToken
          }
        }).subscribe(({ data }) => {
          const messages = [...this.messages.reverse(), ...data['getMessagesByConversationId'].conversation.messages.items];
          this.messages = messages.reverse();
          this.nextMessageToken = data['getMessagesByConversationId'].conversation.messages.nextToken;
          if(scrollToBottom) {
            this.scrollToBottom();
          }
        });
  }

  createConversation() {
    this.apollo.mutate({
      mutation: CREATE_CONVERSATION,
      variables: {
        participantType: "PATIENT",
        participantId: this.patientStateService.patientId
      }
    }).subscribe((result: any) => {
      const data = result.data;
      this.conversationId = data['createConversation']['providerConversationId'];
    });
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    }, 0)
  }

  closeChat() {
    this.navigationService.setChat(false);
  }

  ngOnDestroy(): void {
     this.querySubscription.unsubscribe();
  }
}