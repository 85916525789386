import { MedicalContent, MedicalContentNames } from '../core/model/common.interface';
import { PatientOverviewResponseData, PatientHit, OverviewTableData, PatientOverviewRecord, PatientNotification, Aggregations, RecommendationDetails, Aggregation } from '../core/model/overview.interface';
import { LangType } from '../core/services/state.service';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

export function transformOverviewResponseToTableData(data: PatientHit[], medicalContentNames: MedicalContentNames, lang: LangType, translate: TranslateService, medicalContent: MedicalContent): OverviewTableData {
    const patients: PatientOverviewRecord[] = data.map(hit => {
        const notifications: PatientNotification[] = hit.notifications
            ? Object.values(hit.notifications).map(notification => ({
                notificationId: notification.notificationId,
                diseaseId: notification.diseaseId,
                message: notification.message,
                score: notification.priority,
                type: translate.instant(`overview_page.${notification.type.toLowerCase()}`),
            }))
            : [];
        const recommendations: RecommendationDetails[] = hit.recommendations
            ? Object.keys(hit.recommendations).map(key => ({
                ...hit.recommendations[key],
                label: hit.recommendations[key].title[lang]
            }))
            : [];

        return {
            birthDate: hit.dob ? formatDate(hit.dob, medicalContent) : null,
            externalId: hit.externalId,
            initials: hit.initials,
            lastLogin: changeLoginData(hit.lastLogin, translate, medicalContent),
            lastName: hit.lastName,
            notifications,
            patientId: hit.patientId,
            diseaseId: hit.diseases.map((disease) => medicalContentNames.diseases[disease].name[lang]),
            scenarioId: getScenarioName(hit, translate, medicalContentNames, lang),
            lastEcareDateTime: hit.lastEcareDateTime ? formatDate(hit.lastEcareDateTime, medicalContent) : null,
            recommendations
        }
    });
    return { patients };
}

function getScenarioName(hit: PatientHit, translate: TranslateService, medicalContentNames: MedicalContentNames, lang: LangType): any[] {
    const treatmentplans = hit.treatmentplans || [];
    return treatmentplans.map((treatmentplan) => {
        const treatmentPlanId = treatmentplan.split("|")[1];
        if (treatmentPlanId.includes("NONE")) {
            return translate.instant('overview_page.no_treatmentplan');
        } else {
            return medicalContentNames.scenarios[treatmentPlanId]
                ? medicalContentNames.scenarios[treatmentPlanId]?.name[lang]
                : treatmentPlanId;
        }
    });
}

export function getNamesFromFilterOptions(filterOptions: Aggregations, medicalContentNames: MedicalContentNames, lang: LangType, medicalContent: MedicalContent, translate: TranslateService): Aggregations {
    const mapLabel = (label: string): string => {
        if (medicalContentNames.diseases[label]) {
            return medicalContentNames.diseases[label].name[lang];
        } else if (medicalContentNames.scenarios[label]) {
            return medicalContentNames.scenarios[label].name[lang];
        } else if (medicalContentNames.activities[label]) {
            return medicalContentNames.activities[label].title[lang];
        }
        return label;
    };

    const mapChildren = (parent: Aggregation, childrenCategory: string): Aggregation[] => {
        if (childrenCategory === 'treatmentplans') {

            const disease = medicalContent.diseases.filter((disease) => disease.id === parent.label)[0];

            const filters = filterOptions.treatmentplans
                .filter(treatmentPlan => {
                    return disease.treatmentPlan.some(diseasePlan =>
                        diseasePlan.scenarios.some(scenario => scenario.id === treatmentPlan.label.split("|")[1] && disease.id === treatmentPlan.label.split("|")[0])
                    );
                })
                .map(treatmentPlan => ({
                    ...treatmentPlan,
                    label: mapLabel(treatmentPlan.label.split("|")[1]),
                    id: treatmentPlan.label,
                }));

            const noTreatmentPlanFilters = filterOptions.treatmentplans.filter((treatmentPlan) => treatmentPlan.label === `${parent.label}|NONE`);
            if (noTreatmentPlanFilters.length > 0) {
                filters.push({
                    id: noTreatmentPlanFilters[0].label,
                    label: translate.instant('overview_page.no_treatmentplan'),
                    count: noTreatmentPlanFilters[0].count
                })
            }

            return filters;
        }
        return [];
    };

    return {
        ...(filterOptions.lastEcareDateTime.length > 0 && {
            lastEcareDateTime: filterOptions.lastEcareDateTime
                .sort((a, b) => a.priority - b.priority)
                .map(option => ({
                    ...option,
                    label: option.key[lang],
                    id: option.label
                }))
        }),
        ...(filterOptions.diseases.length > 0 && {
            diseases: filterOptions.diseases.map(option => ({
                ...option,
                label: mapLabel(option.label),
                id: option.label,
                childrenCategory: 'treatmentplans',
                children: mapChildren(option, 'treatmentplans')
            }))
        }),
        ...(filterOptions.careteams?.length > 0 && {
            careteams: filterOptions.careteams.map(option => ({
                ...option,
                label: mapLabel(option.label),
                id: option.label
            }))
        })
    };
}

export function changeLoginData(lastLoginDate: any, translate: any, medicalContent: MedicalContent): string {
    if (lastLoginDate === null) {
        return translate.instant('overview_page.no_last_login_text');
    } else {
        const lastLogin = DateTime.fromISO(lastLoginDate);
        const currentDate = DateTime.now();
        const difference = currentDate.diff(lastLogin, 'months').months;

        if (difference < 1) {
            return formatDate(lastLoginDate, medicalContent);
        } else if (difference < 2) {
            return `> ${Math.floor(difference)} ${translate.instant('overview_page.month_text')}`;
        } else {
            return `> ${Math.floor(difference)} ${translate.instant('overview_page.months_text')}`;
        }
    }
}

export function formatDate(date: any, medicalContent) {
    const utcDate = DateTime.fromISO(date, { zone: 'utc' }).toLocal();
    return utcDate.setZone(medicalContent.timeZone).toFormat(medicalContent.formatting.shortDateFormat);
}

function getNotificationLabel(type: string, translate): string {
    return translate.instant(`overview_page.${type.toLowerCase()}`);
}

