import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DhCommonModule } from '../dh-common/dh-common.module';
import { PasswordlessInitiationComponent } from './passwordless-initiation/passwordless-initiation.component';
import { SMSVerificationComponent } from './sms-verification/sms-verification.component';
import { SharedModule } from '../shared/shared.module';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ProviderSelectionComponent } from './sms-verification/provider-selection/provider-selection.component';
// import { ReactComponentWrapper } from './react-wrapper';

@NgModule({
  declarations: [
    PasswordlessInitiationComponent,
    SMSVerificationComponent,
    EmailVerificationComponent,
    ProviderSelectionComponent
  ],
  imports: [
    CommonModule,
    DhCommonModule,
    SharedModule,
  ]
})
export class AuthModule { }
