import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MedicalContent } from 'src/app/core/model/common.interface';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { StateService } from 'src/app/core/services/state.service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/core/services/navigation.service';
// import { FormsService } from 'src/app/core/services/forms.service';
import { LaunchDarklyService } from 'src/app/core/services/launchDarkly.service';
import * as ld from 'launchdarkly-js-client-sdk';;
@Component({
    selector: 'app-patient',
    templateUrl: './patient.component.html',
    styleUrls: ['./patient.component.scss'],
    providers: [PatientStateService]
})
export class PatientComponent implements OnInit, OnDestroy {
    patientId: string;
    environment: any;
    selectedTab?: string = 'summary';
    hasMedicalContentLoaded: boolean;
    showChat: boolean = false;
    launchDarklyClient: ld.LDClient;

    constructor(private route: ActivatedRoute, private launchDarklyService: LaunchDarklyService, private patientStateService: PatientStateService,
        private stateService: StateService, private http: HttpClient, private communicationService: CommunicationService,
        private navigationService: NavigationService) {
        this.environment = environment
        this.patientId = this.route.snapshot.paramMap.get('patientId');
        if (this.route.snapshot.paramMap.get('editScreen')) {
            navigationService.setTab('profile');
        } else if (this.route.snapshot.routeConfig.path.includes('questionnaire')) {
            navigationService.setTab('questionnaire');
        } else {
            navigationService.setTab('summary');
        }
        sessionStorage.setItem('patientId', this.patientId);
        navigationService.chatObs.subscribe(value => {
            this.showChat = value;
        });
        navigationService.tabObs.subscribe(value => {
            this.selectedTab = value;
        });
    }

    openChat() {
        this.navigationService.setChat(true);
    }

    ngOnInit(): void {

        this.route.params.subscribe((data) => {
            this.patientStateService.setPatientId = data.patientId;
        });
        const medicalContent: MedicalContent = this.stateService.getMedicalContent;
        if (!medicalContent) {
            this.stateService.getMedicalContentData().subscribe();
        }
        this.stateService.medicalContentObs.subscribe((data) => {
            this.hasMedicalContentLoaded = true;
        });
    }

    ngOnDestroy(): void {
        this.patientStateService.resetState();
    }


}
