import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangType, StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  manualUrl: String;
  lang: LangType;
  page_url: string = window.location.href;
  isUSRegion: boolean;
  constructor(public activeModal: NgbModal, public stateService:StateService) { }

  ngOnInit(): void {
    this.manualUrl = 'https://www.dearhealth.com';
    this.isUSRegion = this.page_url.includes(".com");
    // this.stateService.lang.subscribe((lang) => {
    //     this.manualUrl = LangType['EN'] === lang ? 'https://www.dearhealth.com' : 'https://dearhealth.com/nl/patients'
    //   });
  }

}
