import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Disease, MedicalContent, MedicalContentNames, Scenario, Form } from '../model/common.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';



export enum LangType {
  EN = 'en',
  NL = 'nl',
}

export const DEFAULT_LANG: LangType = LangType.NL;

export const DEFAULT_PATIENT_OVERVIEW_SETTINGS: object = {
  sort: '',
  direction: ''
};

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public providerNameSubject$: BehaviorSubject<string>;
  private providerIdSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private scenariosSubject$: BehaviorSubject<Scenario[]> = new BehaviorSubject([]);
  private medicalContentSubject$: BehaviorSubject<MedicalContent> = new BehaviorSubject(null);
  private medicalContentNamesSubject$: BehaviorSubject<MedicalContentNames> = new BehaviorSubject(null);

  private sso$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private embeddedLaunch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private lang$: BehaviorSubject<LangType> = new BehaviorSubject(DEFAULT_LANG);

  private patientOverviewSettings$: BehaviorSubject<object> = new BehaviorSubject(DEFAULT_PATIENT_OVERVIEW_SETTINGS);
  patientOverviewSettings = this.patientOverviewSettings$.pipe(distinctUntilChanged());
  lang = this.lang$.pipe(distinctUntilChanged());
  sso = this.sso$.asObservable();
  embeddedLaunch = this.embeddedLaunch$.asObservable();
  medicalContentObs = this.medicalContentSubject$.asObservable();
  providerIdObs = this.providerIdSubject$.asObservable();
  medicalContentNamesObs = this.medicalContentNamesSubject$.asObservable();
  scenarioObs = this.scenariosSubject$.asObservable();
  istoken = new BehaviorSubject(false);
  keySet: Set<string> =  new Set()
  
  constructor(private http: HttpClient){
      this.providerNameSubject$ = new BehaviorSubject<string>(null);
  }

  setSso(value: boolean): void {
    this.sso$.next(value);
  }

  public get getSso(): boolean {
    return this.sso$.getValue();
  }

  setEmbeddedLaunch(value: boolean): void {
    this.embeddedLaunch$.next(value);
  }

  public get getEmbeddedLaunch(): boolean {
    return this.embeddedLaunch$.getValue();
  }
  
  setLang(lang: LangType): void {
    this.lang$.next(lang);
  }
  setTokenState(token): void {
    this.istoken.next(token);
  }

  public get providerId(): string {
    return this.providerIdSubject$.getValue();
  }

  public get providerName(): string {
    return this.providerNameSubject$.getValue();
  }

  public setPatientOverviewSettings(sort: string, direction: string): void {
    const sortDirection = direction.toUpperCase();
    this.patientOverviewSettings$.next({ sort, sortDirection });
  }

  public resetPatientOverviewSettings(): void {
    this.patientOverviewSettings$.next(DEFAULT_PATIENT_OVERVIEW_SETTINGS);
  }



  /* Setters */
  public set setProviderId(providerId: string) {
    this.providerIdSubject$.next(providerId);
  }

  public set setProviderName(providerName: string) {
    this.providerNameSubject$.next(providerName);
  }

  public set setMedicalContent(medicalContent: MedicalContent) {
    this.medicalContentSubject$.next(medicalContent);
  }

  public set setMedicalContentNames(medicalContentNames: MedicalContentNames) {
    this.medicalContentNamesSubject$.next(medicalContentNames);
  }

  public set setScenarios(scenarios: Scenario[]) {
    this.scenariosSubject$.next(scenarios);
  }


  /* getters */
  public get getMedicalContent(): MedicalContent {
    return this.medicalContentSubject$.getValue();
  }

  public get getMedicalContentNames(): MedicalContentNames {
    return this.medicalContentNamesSubject$.getValue();
  }

  public get getScenarios(): Scenario[] {
    return this.scenariosSubject$.getValue();
  }

  getRecommenderData(recommenderName) {
    const recommenderText = this.http.get(`${environment.medicalContentEndpoint}/providers/${sessionStorage.getItem('providerId')}/questionnaire_text/${recommenderName}.json`);
    return recommenderText;
  }

  // getMedicalContentData() {
  //   const medicalContentNamesObs = this.http.get(`${environment.medicalContentEndpoint}/providers/${sessionStorage.getItem('providerId')}/names.json`);
  //   const medicalContentsObs = this.http.get(`${environment.medicalContentEndpoint}/providers/${this.providerId}`);
  //   forkJoin({ medicalContent: medicalContentsObs, medicalContentNames: medicalContentNamesObs })
  //     .subscribe(result => {
  //       const medicalContent = result.medicalContent as MedicalContent;
  //       this.setMedicalContent = Object.seal(medicalContent);
  //       this.setMedicalContentNames = result.medicalContentNames as MedicalContentNames;
  //       this.setScenarios = this.buildScenarios(medicalContent.diseases);
  //   });
  // }

  getMedicalContentData(): Observable<{ medicalContent: MedicalContent, medicalContentNames: MedicalContentNames }> {
    const medicalContentNamesObs = this.http.get<MedicalContentNames>(`${environment.medicalContentEndpoint}/providers/${sessionStorage.getItem('providerId')}/names.json`);
    const medicalContentsObs = this.http.get<MedicalContent>(`${environment.medicalContentEndpoint}/providers/${this.providerId}`);
    return forkJoin({ medicalContent: medicalContentsObs, medicalContentNames: medicalContentNamesObs }).pipe(
      map(result => {
        const medicalContent = result.medicalContent;
        this.setMedicalContent = Object.seal(medicalContent);
        this.setMedicalContentNames = result.medicalContentNames;
        this.setScenarios = this.buildScenarios(medicalContent.diseases);
        return result;
      })
    );
  }

  buildScenarios(diseases: Disease[]): Scenario[] {
    const scenarios = [];
    diseases.forEach(disease =>
      disease.treatmentPlan.forEach(treatmentPlan =>
        treatmentPlan.scenarios.forEach(scenario =>
          scenarios.push({ ...scenario, diseaseId: disease.id })
        )
      )
    );
    return scenarios;
  }

}

