import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule} from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { DhCommonModule } from 'src/app/dh-common/dh-common.module';
import { NewOverviewComponent } from './new-overview.component';
import { CreatePatientComponent } from './components/create-patient/create-patient.component';
import { ReactComponentWrapper } from './react-wrapper';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    NewOverviewComponent,
    ReactComponentWrapper,
    CreatePatientComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatIconModule,
    DhCommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewOverviewModule { }
