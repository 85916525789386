import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { PatientService } from '../core/services/patient.service';
import { LoaderService } from '../core/services/loader.service';
import { Aggregations, ColumnDefinition, OverviewTableData, PatientHit, PatientOverviewRecord, PatientOverviewResponseData } from '../core/model/overview.interface';
import { transformOverviewResponseToTableData, getNamesFromFilterOptions } from './utils';
import { CareTeam, Disease, MedicalContent, MedicalContentNames } from 'src/app/core/model/common.interface';
import { LangType, StateService } from '../core/services/state.service';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../core/services/message.service';
import { Apollo } from 'apollo-angular';
import { READ_NOTIFICATION } from '../core/constants/graphql.query.constants';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { CreatePatientComponent } from './components/create-patient/create-patient.component';
import { MatDialog } from '@angular/material/dialog';
import jwt_decode from "jwt-decode";
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-new-overview',
  templateUrl: './new-overview.component.html',
})
export class NewOverviewComponent implements OnInit {
  public medicalContentNames: MedicalContentNames;
  public overviewTableData: OverviewTableData;
  public filterOptions: Aggregations;
  diseases: Disease[];
  medicalContent: MedicalContent;
  lang: LangType;
  totalRecords = 0;
  itemsPerPage = 20;
  patientList: PatientHit[];

  defaultPayload = {
    addFilterValues: true,
    aggregates: true,
    limit: this.itemsPerPage,
    last: -1
  }

  payload: any;

  columnOptions: ColumnDefinition[] = [
    {
      "id": "externalId",
      "label": "External ID",
      "visible": true,
      "enableSorting": false,
    },
    {
      "id": "lastName",
      "label": "Full Name",
      "visible": true,
      "enableSorting": false
    },
    {
      "id": "dob",
      "label": "Birth Date",
      "visible": true,
      "enableSorting": false
    }, {
      "id": "diseases",
      "label": "Disease",
      "visible": true,
      "enableSorting": false
    }, {
      "id": "treatmentplans",
      "label": "Scenario",
      "visible": false,
      "enableSorting": false
    }, {
      "id": "lastLogin",
      "label": "Last Login",
      "visible": false,
      "enableSorting": false
    }, {
      "id": "lastEcareDateTime",
      "label": "Last eCare Date",
      "visible": false,
      "enableSorting": false
    }, {
      "id": "notifications",
      "label": "Notifications",
      "visible": true,
      "enableSorting": true,
      "sortKey": "maxNotificationScore"
    }, {
      "id": "recommendations",
      "label": "Recommendation",
      "visible": true,
      "enableSorting": false
    }
  ];

  constructor(private patientService: PatientService, private apollo: Apollo, private messageService: MessageService,
    private stateService: StateService, private translate: TranslateService, private route: Router, private loaderService: LoaderService,
    private dialogRef: MatDialog, private viewRef: ViewContainerRef, private cdr: ChangeDetectorRef) {
    this.stateService.lang.subscribe((data) => {
      this.lang = data;
      this.translate.use(this.lang);
      this.updateColumnOptionsLabels();
    });
    this.translate.onLangChange.subscribe(() => {
      this.updateColumnOptionsLabels();
    })
    this.stateService.medicalContentNamesObs.subscribe(data => {
      this.medicalContentNames = data;
    });
    this.columnOptions = localStorage.getItem('columnDefinitions') ? JSON.parse(localStorage.getItem('columnDefinitions')) : this.columnOptions;
  }

  updateColumnOptionsLabels(): void {
    this.columnOptions = this.columnOptions.map(column => ({
      ...column,
      label: this.translate.instant(`overview_page.${column.id}`)
    }));
  }


  ngOnInit(): void {
    this.loaderService.show();
    const previousPayload = sessionStorage.getItem('payload') ? JSON.parse(sessionStorage.getItem('payload')) : {};
    if(previousPayload) {
      if(previousPayload.sort) {
        this.updateSortColumn(previousPayload);
      } 
      if(previousPayload.last > -1) {
        previousPayload.limit = previousPayload.last + this.itemsPerPage + 1;
        previousPayload.last = -1;
      }
    }
    
    const decoded: any = jwt_decode(sessionStorage.getItem('pracAuthToken'));
    this.payload = { ...this.defaultPayload, ...previousPayload },
    combineLatest([
      this.stateService.lang,
      this.stateService.providerIdObs,
      this.stateService.getMedicalContentData(),
      this.patientService.getOpenSearchOverview( this.payload, true)
    ]).subscribe(
      ([lang, providerId, { medicalContent, medicalContentNames }, response]: [LangType, string, { medicalContent: MedicalContent, medicalContentNames: MedicalContentNames }, PatientOverviewResponseData]) => {    
        this.lang = lang;
        this.medicalContent = medicalContent;
        this.medicalContentNames = medicalContentNames;
        this.patientList = response.hits;
        this.overviewTableData = transformOverviewResponseToTableData(this.patientList, this.medicalContentNames, lang, this.translate, this.medicalContent);
        this.totalRecords = response.total.value;
        this.filterOptions = getNamesFromFilterOptions(response.aggregations, this.medicalContentNames, this.lang, this.medicalContent, this.translate);
        this.updateColumnOptionsLabels();

        if (medicalContent) {
          const careTeams: string[] = decoded.careTeams;
          const filteredDiseases = [];
          careTeams.forEach((careTeamId: string) => {
            const filderedData = medicalContent.careTeams?.filter((careTeam: CareTeam) => {
              return careTeamId === careTeam.id
            })[0];
            if (filderedData) {
              filteredDiseases.push(filderedData.diseases);
            }
          });
          this.diseases = filteredDiseases.flat() as Disease[];
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }, () => {
        this.loaderService.hide();
      }
    );
  }

  fetchOverviewData(payload: any, showLoader: boolean, loadMore: boolean): void {
    if (showLoader) {
      this.loaderService.show();
    }
    this.patientService.getOpenSearchOverview(payload, showLoader).subscribe(
      (response: PatientOverviewResponseData) => {
        this.totalRecords = response.total.value;
        this.patientList = loadMore ? this.patientList.concat(response.hits) : response.hits;
        this.overviewTableData = transformOverviewResponseToTableData(this.patientList, this.medicalContentNames, this.lang, this.translate, this.medicalContent);
        this.filterOptions = getNamesFromFilterOptions(response.aggregations, this.medicalContentNames, this.lang, this.medicalContent, this.translate);
      },
      (error) => {
        console.error('Error fetching patient overview data:', error);
      }, () => {
        this.loaderService.hide();
      }
    );
  }

  updateSortColumn(previousPayload): void {
      this.columnOptions = this.columnOptions.map(column => ({
        ...column,
        enableSorting:
          previousPayload.sort === column.id || previousPayload.sort === column.sortKey,
        sortDirection:
          previousPayload.sort === column.id || previousPayload.sort === column.sortKey
            ? previousPayload.sort_dir
            : undefined
      }));
  }

  handleFilterChanges(data): void {
    this.payload = this.payload || this.defaultPayload;
    this.payload.limit = this.itemsPerPage;
    this.payload.last = -1;
    if (data.filterOptions) {
      for (const [key, value] of Object.entries(data.filterOptions)) {
        if (data.filterOptions[key].length === 0) {
          delete data.filterOptions[key];
          if (this.payload.filters && this.payload.filters[key]) {
            delete this.payload.filters[key];
          }
        }
      }
      if (Object.keys(data.filterOptions).length > 0) {
        this.payload = { ...this.payload, filters: { ...this.payload.filters, ...data.filterOptions } }
      }
    }

    if (data.sortOptions && Object.keys(data.sortOptions).length > 0) {
      const sortId = data.sortOptions.sortKey ?? data.sortOptions.id;
      this.payload = { ...this.payload, ...{ "sort": sortId, "sort_dir": data.sortOptions.sortDirection ?? "desc" } };
    }

    if (data.searchOptions) {
      const { searchPhrase, startDate, endDate } = data.searchOptions;
      if (searchPhrase !== '') {
        this.payload = { ...this.payload, ...{ "searchPhrase": searchPhrase } }
      } else {
        delete this.payload.searchPhrase;
      }
      // let formattedStartDate = startDate;
      // let formattedEndDate = endDate;
      // if(typeof startDate === 'string') {
      //   formattedStartDate = startDate;
      // }else {
      //   formattedStartDate = startDate ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd') : null;
      // }

      // if(typeof endDate === 'string') {
      //   formattedEndDate = endDate;
      // } else {
      //   formattedEndDate = endDate ? DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd') : null;
      // }

      const formatDate = (date: string | Date | null) => 
        typeof date === "string" ? date : date ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd") : null;
      
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      
      const datePayloadData = {
        ...(formattedStartDate && { gte: formattedStartDate }),
        ...(formattedEndDate && { lte: formattedEndDate })
      };

      if (Object.keys(datePayloadData).length > 0) {
        this.payload = {
          ...this.payload,
          filters: {
            ...this.payload.filters,
            dob: {
              range: {
                dob: datePayloadData
              }
            }
          }
        };
      } else if(this.payload.filters?.dob) {
        console.log(this.payload);
        delete this.payload.filters.dob;
      }
    }
    this.fetchOverviewData(this.payload, true, false);
  }

  handleNotificationChange({ notificationId, rowData }): void {
    const notificaitonData = rowData.notifications.find((notification: any) => notification.notificationId === notificationId);
    const readNotificaionObs = this.apollo.mutate({
      mutation: READ_NOTIFICATION,
      variables: {
        input: {
          diseaseId: notificaitonData.diseaseId,
          patientId: rowData.patientId,
          notificationIds: [notificationId]
        }
      }
    });
    readNotificaionObs
      .subscribe((data) => {
        console.log("Notificaiton resolved", data);
      }, (err) => {
        this.messageService.showGenericError();
      });
  }
  handleRowClick(data: PatientOverviewRecord): void {
    sessionStorage.setItem('patientId', data.patientId);
    sessionStorage.setItem('payload', JSON.stringify(this.payload));
    this.route.navigate([`/patient/${data.patientId}`], { state: { patientId: data.patientId } });
  }

  handleLoadMore(): void {
    if (!this.payload) {
      this.payload = { ...this.defaultPayload, limit: this.itemsPerPage, last: this.defaultPayload.last + this.itemsPerPage };
    } else {
      console.log(this.payload);
      // This is to handle when user scrolls and navigates to patient page and comesback to overview
      if(this.payload.limit > this.itemsPerPage) {
        this.payload.last = this.payload.limit - 1;
        this.payload.limit = this.itemsPerPage;
      } else {
        this.payload = { ...this.payload, limit: this.itemsPerPage, last: this.payload.last + this.itemsPerPage };
      }
    }
    if (this.payload.last < this.totalRecords) {
      this.fetchOverviewData(this.payload, false, true);
    }
  }

  handlePatientCreate(): void {
    const self = this
    const dialog = this.dialogRef.open(CreatePatientComponent, {
      width: '700px',
      disableClose: true,
      viewContainerRef: this.viewRef,
      data: this.diseases
    })

    dialog.afterClosed().subscribe((result) => {
      result.diseaseId[0] = self.medicalContentNames.diseases[result.diseaseId[0]].name[self.lang];
      if (result) {
        this.overviewTableData = {
          ...this.overviewTableData,
          patients: [result, ...this.overviewTableData.patients],
        };
      }
    })
  }
}