import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { LoaderInterceptor } from '../app/core/services/loader.interceptor';
import { LoaderService } from '../app/core/services/loader.service';
import { NavigationService } from '../app/core/services/navigation.service';
import { CommunicationService } from 'src/app/core/services/communication.service'
import { AuthGuardService } from 'src/app/core/services/auth-guard.service'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PatientModule } from './patient/patient_module/patient.module';
import { OverviewModule } from './overview/overview_module/overview.module';
import { SharedModule } from './shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AfterLaunchModule } from './shared/component/after-launch/after-launch.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
import { MatRadioModule } from '@angular/material/radio'
import { GraphQLModule } from './graphql.module';
import { DatadogService } from './core/services/datadog.service';
import { LaunchDarklyService } from './core/services/launchDarkly.service';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { DhMatDateFormat } from './core/adapters/date-format-factory';
import { AuthModule } from './auth/auth.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NewOverviewModule } from './new-overview/new-overview.module';

// export function initializeLaunchDarkly(launchDarklyService: LaunchDarklyService): Promise<any> {
//     return new Promise<void>((resolve) => {
//         launchDarklyService.init();
//         resolve();
//     });
// }

// export function initializeLaunchDarkly(launchDarklyService: LaunchDarklyService) {
//     return async () => {
//       return launchDarklyService.init();
//     }
//   }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        PatientModule,
        OverviewModule,
        AuthModule,
        AfterLaunchModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        MatDialogModule,
        MatRadioModule,
        MatMenuModule,
        MatSortModule,
        GraphQLModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        NewOverviewModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormlyModule.forRoot(),
        FormlyBootstrapModule
    ],
    providers: [
        DatadogService,
        MatDialog,
        LaunchDarklyService,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializeLaunchDarkly,
        //     deps: [LaunchDarklyService],
        //     multi: true
        // },
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        MatSnackBar,
        CommunicationService,
        NavigationService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useClass: DhMatDateFormat },
        AuthGuardService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http);
}

