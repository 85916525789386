import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LaunchDarklyService } from '../services/launchDarkly.service'; 

@Injectable({
  providedIn: 'root'
})
export class OverviewGuard implements CanActivate {
  constructor(private launchDarklyService: LaunchDarklyService, private router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const flagValue = await this.launchDarklyService.checkFeatureFlag('enable-overview-v2').toPromise();
    const targetRoute = flagValue ? '/overview-v2' : '/overview';
    if (state.url !== targetRoute) {
      this.router.navigate([targetRoute]);
    }
    return true;
  }
}
