<div class="card">
    <div class="card-body">
        <div class="card-text">
            <span>{{ isUSRegion ? 'About DEARhealth' : 'Over DEARhealth'}}</span>
            <ul class="list-group list-group-flush">

                <li *ngIf="isUSRegion" class="list-group-item">
                    <div class="d-flex flew-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-caution">
                                <span class="sr-only">
                                    Caution
                                </span>
                            </i>
                        </div>
                        <div>
                            CAUTION Investigational device. Limited by Federal (or United States) law to investigational
                            use.
                        </div>
                    </div>
                </li>

                <li class="list-group-item">
                    <div class="d-flex flew-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-manufacturer">
                                <span class="sr-only">
                                    Manufacturer
                                </span>
                            </i>
                        </div>
                        <div>
                            DEAR Health Netherlands B.V.<br>
                            Bargelaan 200<br>
                            2333 CW Leiden<br>
                            www.dearhealth.com
                        </div>
                    </div>
                </li>
                <li *ngIf="!isUSRegion" class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column mr-1">
                            <div>
                                <i class="ce-icon ce mr-2"></i>
                                <i class="ce-icon ce-md"></i>
                            </div>
                        </div>
                    </div>
                    <div class="ml-2">1912</div>
                </li>
                <li *ngIf="!isUSRegion" class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-ref">
                            </i>
                        </div>
                        <div>
                            08720299126015
                        </div>
                    </div>
                </li>
                <li *ngIf="!isUSRegion" class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-udi">
                            </i>
                        </div>
                        <div>
                            (01) 08720299126015 <br>
                            (11) 20230405 <br>
                            (8012) v2.2
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-date-of-manufacture">
                            </i>
                        </div>
                        <div>
                            2023-04-05
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-sn">
                            </i>
                        </div>
                        <div>
                            v2.2
                        </div>
                    </div>
                </li>
                <li *ngIf="isUSRegion" class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-caution">
                                <span class="sr-only">Caution:</span>
                            </i>
                        </div>
                        <div>
                            <p>There are certain circumstances in which DEARhealth is contraindicated:</p>
                            <ul class="caution-list">
                                <li>There is no Internet connection available.</li>
                                <li>The user has no computerized device.</li>
                                <li>The user has impaired visual function.</li>
                                <li>The user has no basic knowledge of the use of computers or mobile phones. </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2">
                            <i class="ce-icon ce-instructions"><span class="sr-only">Instructions:</span>
                            </i>
                        </div>
                        <div>
                            <ng-container *ngIf="isUSRegion">
                                Instructions for use are supplied in electronic form instead of paper form.
                                <br>URL: <a href="https://www.dearhealth.com"
                                    target="_blank">https://www.dearhealth.com</a>
                                <br>Email: support@dearhealth.com
                            </ng-container>
                            <ng-container *ngIf="!isUSRegion">
                                {{'about.instruction_line1'| translate }}
                                <a href="{{manualUrl}}" target="_blank">{{manualUrl}}</a>
                                <br>
                                {{'about.instruction_line2'| translate}}<br>
                            </ng-container>
                            <ng-container *ngIf="!isUSRegion">{{'about.instruction_line3'|
                                translate}}</ng-container><br>
                        </div>
                    </div>
                </li>
                <!-- <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2"><i class="ce-icon ce-instructions"><span
                                    class="sr-only">Instructions:</span></i></div>
                        <div>
                            <ng-container *ngIf="isUSRegion; else translatedInstructions">
                                Instructions: <a href="{{manualUrl}}" target="_blank">{{manualUrl}}</a><br>
                                Follow the steps as mentioned in the manual.<br>
                                Additional instructions for US region.<br>
                            </ng-container>
                            <ng-template #translatedInstructions>
                                {{'about.instruction_line1'| translate }} <a href="{{manualUrl}}"
                                    target="_blank">{{manualUrl}}</a><br>
                                {{'about.instruction_line2'| translate}}<br>
                                <ng-container *ngIf="!isUSRegion">{{'about.instruction_line3'|
                                    translate}}</ng-container><br>
                            </ng-template>
                        </div>
                    </div>
                </li> -->
                <!-- <li class="list-group-item">
                    <div class="d-flex flex-row">
                        <div class="mr-2"><i class="ce-icon ce-instructions"><span
                                    class="sr-only">Instructions:</span></i></div>
                        <div>
                            {{ getInstructionLine1() }} <a href="{{manualUrl}}" target="_blank">{{manualUrl}}</a>
                            <br>
                            {{ getInstructionLine2() }}<br>
                            <ng-container *ngIf="!isUSRegion">{{ getInstructionLine3() }}</ng-container><br>
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</div>