<app-header [isNewHeader]="true"></app-header>
<div>
    <app-loader></app-loader>
    <app-react-component
    [payload]="payload"
     [totalCount]="totalRecords" [data]="overviewTableData" [filterOptions]="filterOptions" [columnOptions]="columnOptions"  [lang]="lang"
    (filterChanges)="handleFilterChanges($event)"
    (notificationChange)="handleNotificationChange($event)"
    (rowClick)="handleRowClick($event)"
    (loadMore)="handleLoadMore()"
    (patientCreate)="handlePatientCreate()"
    ></app-react-component>
</div>