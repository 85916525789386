import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { isDate } from 'moment';
import { Apollo } from "apollo-angular";
import { APP_CONSTANTS } from 'src/app/core/constants/app.constants';
import { monthsMap } from 'src/app/core/constants/calendar.constant';
import { Activity, IEventActivityType } from 'src/app/core/model/patient.interface';
import { PatientStateService } from 'src/app/core/services/patient-state.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LangType, StateService } from 'src/app/core/services/state.service';
import { ADD_ACTIVITY } from 'src/app/core/constants/graphql.query.constants';
import { MessageService } from 'src/app/core/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { DhMatDateFormat } from 'src/app/core/adapters/date-format-factory';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-update-activity',
  templateUrl: './update-activity.component.html',
  styleUrls: ['./update-activity.component.scss'], 
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ]
})
export class UpdateActivityComponent implements OnInit {

  activityForm: FormGroup;
  lang: LangType;
  calendarOpenDate: Date;
  carePathwayActivities: Map<string, any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
 
     private dateAdapter: DateAdapter<any>,
     @Inject(MAT_DATE_FORMATS) public config: DhMatDateFormat,
    private stateService: StateService,
    private apollo: Apollo,
    private messageService: MessageService,
    private patientStateService: PatientStateService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UpdateActivityComponent>, private patientService: PatientService,
  ) {
  }

  public get activityCtrl() {
    return this.activityForm.get('activity');
  }

  public get startDateCtrl() {
    return this.activityForm.get('startDate');
  }

  ngOnInit(): void {
    this.stateService.lang.subscribe(res => {
      this.lang = res;
      this.dateAdapter.setLocale(res);
    });
    this.config.dateFormat = this.stateService.getMedicalContent.formatting.shortDateFormat.replace('dd', 'DD');
    this.createFormControls();
    if (this.data.edit) {
      this.activityForm.patchValue(this.data.selectedActivityData);
    }
    this.calendarOpenDate = new Date(this.data.year, monthsMap.get(this.data.month) - 1, 1);
    this.patientStateService.carePathWayActivities$.subscribe((activities: Activity[]) => {
      this.carePathwayActivities = new Map(activities.map(activity => [activity.activityId, activity]));
    });
  }

  createFormControls(): void {
    this.activityForm = new FormGroup({
      activity: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required)
    })
  }

  activate(): void {
    if (this.activityForm.valid) {
      const startDate = (isDate(this.startDateCtrl.value)
        ? moment(this.startDateCtrl.value).set('hour', 10).format(APP_CONSTANTS.ISO_FORMAT)
        : moment(this.startDateCtrl.value.toDate()).set('hour', 10).format(APP_CONSTANTS.ISO_FORMAT));


      if (this.data.edit) {
        const { selectedActivityData: { activity } } = this.data as { selectedActivityData: { startDate: Date, activity: Activity } };
        const activityId = activity.activityId;
        this.patientService.editActivity(this.stateService.providerId, this.patientStateService.patientId,
          activityId, { dateTimeToUpdateTo: startDate })
          .subscribe(() => {
            this.data.selectedActivityData.activity.date = startDate;
            const oldActivity = this.carePathwayActivities.get(activityId);
            this.carePathwayActivities.set(activityId, { ...oldActivity, datetime: startDate });
            this.patientStateService.setCarePathwayActivites = [...this.carePathwayActivities.values()];
            this.dialogRef.close();
          });
      } else { // add
        const { activityType } = this.data as { year: number, month: string, activityType: IEventActivityType };
        const activityDefinitionId: string = this.activityCtrl.value;
        const activity = activityType.activities.find((act) => act.id === activityDefinitionId);

        const payload = {
          activityDefinitionId: activityDefinitionId,
          providerId: this.stateService.providerId,
          patientId: this.patientStateService.patientId,
          diseaseId: this.patientStateService.diseaseId,
          type: activityType.type,
          activityId: uuidv4(),
          datetime: startDate
        }

        const addActivityObs = this.apollo.mutate({
          mutation: ADD_ACTIVITY,
          variables: {
            input: payload
          }
        });
        addActivityObs.subscribe((response: any) => {
          this.carePathwayActivities.set(response.data['addActivity'].activityId, {
            activityId: response.data['addActivity'].activityId,
            type: activityType.type,
            datetime: startDate,
            activityDefinitionId: payload.activityDefinitionId,
            status: null,
            // questionnaireId: activity.questionnaireId,
            details: activity.title,
          });
          this.patientStateService.setCarePathwayActivites = [...this.carePathwayActivities.values()];
          this.messageService.showSuccessMessage(this.translate.instant('care_pathway.add_activity.success'));
          this.dialogRef.close();
        }, (err) => {
          this.messageService.showGenericError();
        })

      }

    } else {
      this.activityForm.markAllAsTouched();
    }
  }
}
