import { Component, OnInit } from '@angular/core';
import { Patient } from '../../core/model/patient.interface';
import { environment } from '../../../environments/environment';
import { LangType, StateService } from '../../core/services/state.service';
import { MedicalContent } from 'src/app/core/model/common.interface';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

    endPoint = environment.endpoint + environment.services.patient;
    patientData: Observable<Patient[]>;
    patientId: string;
    providerId: string;
    diseases: string[];
    diseaseId: string;
    diseaseName: string;
    lang: LangType;
    loaderEnabled = true;
    medicalContent: MedicalContent;

    constructor(
        private stateService: StateService
    ) { }

    ngOnInit(): void {
        this.stateService.lang.subscribe(res => {
            this.lang = res;
        });
        this.stateService.providerIdObs.subscribe((data) =>
            this.stateService.getMedicalContentData().subscribe()
        );
        this.stateService.medicalContentObs.subscribe((data) => this.medicalContent = data)
    }

    loaderStateChange = (value: boolean) => {
        this.loaderEnabled = value;
    }
}
